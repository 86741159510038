import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'; 
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box, Collapse, Heading, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, Accordion,
  AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, VStack, List, Icon,
  ListItem, HStack, Button, useToast, Input, InputGroup, InputLeftElement, Link, Switch, Tooltip, Spinner
} from '@chakra-ui/react';
import { ExternalLinkIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon, CloseIcon, CheckCircleIcon, ViewOffIcon, LinkIcon } from '@chakra-ui/icons';
import { IoMdHappy, IoMdSad } from 'react-icons/io';
import { FaRegUser, FaSearch, FaSync } from 'react-icons/fa';
import BlueBox from '../../BlueBox';

const Clients = () => {
  const toast = useToast();
  const [openRows, setOpenRows] = useState({});
  const [openAskAboutRows, setOpenAskAboutRows] = useState({});
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [loadingCorrespondence, setLoadingCorrespondence] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [showArchivedCorrespondence, setShowArchivedCorrespondence] = useState(false);
  const [showArchivedActionItems, setShowArchivedActionItems] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/'
  const [resyncLoading, setResyncLoading] = useState(true);

  const handleToggle = async (id) => {
    console.log(`Loading correspondence for client ID: ${id}`);
    setLoadingCorrespondence(true);

    // Toggle the open state first
    const isOpen = openRows[id];
    setOpenRows(prev => ({
      ...prev,
      [id]: !isOpen
    }));

    if (isOpen) {
      // If the row is already open, just set loading to false and return
      setLoadingCorrespondence(false);
      return;
    }

    console.log("Toggling row:", id);
    const token = Cookies.get('jwtToken');
    console.log("Token:", token);
    console.log('Cookies:', Cookies.get());

    try {
      const correspondenceHistory = await axios.get(serverUrl + '/api/clients/' + id + '/correspondence-history', { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Correspondence history:', correspondenceHistory.data);
      
      setClients(prev => prev.map(client => client.id === id ? { ...client, correspondenceHistory: correspondenceHistory.data } : client));
    } catch (error) {
      console.error("Error fetching correspondence history:", error);
    } finally {
      setLoadingCorrespondence(false); // Ensure loading state is reset
    }
  };
  const handleAskAbout = async (id) => {
    setQuestions([]);
    setAnswers([]);
    setQuestion('');
    setOpenAskAboutRows({
      [id]: !openAskAboutRows[id]
    });
  };
  const getCorrespondenceHistory = async (id, showArchived) => {
    const token = Cookies.get('jwtToken');
    const correspondenceHistory = await axios.get(serverUrl + '/api/clients/' + id + '/correspondence-history?showArchived=' + showArchived, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
    console.log('Correspondence history:', correspondenceHistory.data);
    setClients(prev => prev.map(client => client.id === id ? { ...client, correspondenceHistory: correspondenceHistory.data } : client));
  };
  const handlePageChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(serverUrl + '/api/clients?page=' + page + '&pageSize=' + pageSize);
        console.log("Fetched clients:", response.data);
        setClients(response.data.clients);
        setSearchResults(response.data.clients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching calls:", error.response ? error.response.data : error.message);
        toast({
          title: "Error fetching calls.",
          description: `There was an error fetching calls from the server: ${error.response ? error.response.data.details : error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [page, pageSize]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    
   const results = clients.filter(client =>
     client.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
     client.lastName?.toLowerCase().includes(searchTerm?.toLowerCase())
   );
    setSearchResults(results);
  }, [searchTerm, clients]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleSubmitQuestion = async (id, question) => {
    setLoadingQuestion(true);
    const token = Cookies.get('jwtToken');
    let q = [...questions, question];
    const response = await axios.post(serverUrl + '/api/clients/' + id + '/ask-about', { questions: q, answers: answers.map(answer => answer.answer) }, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
    console.log("Submitting question:", question);
    console.log("Response:", response);

    setAnswers(prev => [...prev, response.data.completion]);
    setQuestions(prev => [...prev, question]);
    setLoadingQuestion(false);
    setQuestion('');
  };

  const handleResync = async () => {
    setResyncLoading(true);
    console.log('Clients Component: handleResync - Initiating resync process');
    try {
      console.log('Clients Component: handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };
  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleViewEmail = (id) => {

    axios.get(`${serverUrl}/api/emails/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        return response.data;
      })
      .then(emailData => {
        console.log('Email Data:', emailData);
        // Check if the webLink is available in the email data
        if (emailData && emailData.metadata.webLink) {
          console.log('Navigating to email web link:', emailData.metadata.webLink);
          window.open(emailData.metadata.webLink, '_blank'); // Open the email link in a new tab
        } else {
          console.error('No web link available for this email');
          toast({
            title: "Error fetching email.",
            description: `There was an error fetching the email from the server: No web link available for this email`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  };

  useEffect(() => {
    fetchJobs(true);
  }, []);

  return (
    <Box bg="white" p={8} overflowY="auto">
      <HStack justify="space-between" mb={4}>
        <Heading color={'#00417D'} fontSize={'32px'}>Clients</Heading>
        <Button
          onClick={handleResync}
          isLoading={resyncLoading}
          loadingText="Resyncing"
          colorScheme="blue"
          leftIcon={<Icon as={FaSync} />}
        >
          Resync CRM
        </Button>
      </HStack>
      <Table variant="unstyled" mt={12} size="xs" border={'none'}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Last Correspondence</Th>
            <Th></Th>
            <Th>
              <Box display='flex' justifyContent='flex-end'>
                <InputGroup w={'auto'}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={FaSearch} color={'#A1A1A1'} w={3} mb={'6px'} />}
                  />
                  <Input
                    placeholder="Search for a client"
                    _placeholder={{
                      color: '#A1A1A1',
                      fontWeight: 700,
                      textAlign: 'center'
                    }}
                    borderRadius={'6px'}
                    size="sm"
                    w='270px'
                    value={searchTerm}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Box>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {searchResults.map(client => (
            <React.Fragment key={client.id}>
              <Tr>
                <Td><Text>{client.firstName} {client.lastName}</Text></Td>
                <Td>
                  <Text>
                    {client.emails?.length > 0 ? formatDate(client.emails[0].receivedAt) : client.calls?.length > 0 ? formatDate(client.calls[0].startTime) : 'No correspondence'}
                  </Text>
                </Td>
                <Td>
                  <Button variant="link" color={'#00417D'} size="md" onClick={() => handleAskAbout(client.id)}>Ask About {client.firstName}</Button>
                </Td>
                <Td textAlign={'right'}>
                  <IconButton
                    aria-label="Expand row"
                    icon={openRows[client.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    onClick={() => handleToggle(client.id)}
                    bg={'white'}
                  />
                </Td>
              </Tr>
              {loadingCorrespondence && openRows[client.id] && (
                <Tr>
                  <Td colSpan="4" textAlign="center">
                    <Spinner size="sm" />
                  </Td>
                </Tr>
              )}
              {openAskAboutRows[client.id] && (
                <Tr key={`${client.id}-ask-about`}>
                  <Td colSpan="4">
                    <Collapse in={openAskAboutRows[client.id]}>
                      <Box p={4} mt={2} shadow="md" borderWidth="1px" bg={'#00417D33'} borderRadius={'8px'}>
                        <HStack justify="space-between" mb={4}>
                          <Heading fontSize="xl" mb={2} color={'#00345B'}>Ask About {client.firstName}</Heading>
                          <IconButton
                            aria-label="Close"
                            icon={<CloseIcon />}
                            onClick={() => handleAskAbout(client.id)}
                            bg={'transparent'}
                          />
                        </HStack>
                        <HStack mb={4}>
                          <Input type="text" placeholder="Enter your question" bg={'transparent'} border={'1px solid #00345B'} borderRadius={'6px'} value={question} onChange={(e) => setQuestion(e.target.value)} />
                          <Button colorScheme="blue" onClick={() => handleSubmitQuestion(client.id, question)} isLoading={loadingQuestion}>Submit</Button>
                        </HStack>
                        {answers.map((answer, index) => (
                          <Box key={index} mb={4}>
                            <Text fontWeight="bold">Q: {questions[index]}</Text>
                            <Text>A: {answer.answer}</Text>
                            {answer.source === 'vector' && answer.vectorMetadata && (
                              <HStack mt={2}>
                                <LinkIcon />
                                <Link 
                                  color="blue.500" 
                                  href={`#${answer.vectorMetadata.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (answer.vectorMetadata.callId) {
                                      const newTabUrl = `/transcripts/${answer.vectorMetadata.callId}`;
                                      window.open(newTabUrl, '_blank');
                                    } else {
                                      handleViewEmail(answer.vectorMetadata.emailId);
                                    }
                                  }}
                                >
                                  {answer.vectorMetadata.subject} - { answer.vectorMetadata.startTime ? formatDate(answer.vectorMetadata.startTime) : (answer.vectorMetadata.receivedAt)}
                                </Link>
                              </HStack>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Collapse>
                  </Td>
                </Tr>
              )}
              {openRows[client.id] && !loadingCorrespondence && (
                <Tr key={`${client.id}-details`}>
                  <Td colSpan="4">
                    <Collapse in={openRows[client.id]}>
                      <Box p={4} mt={2} shadow="md" borderWidth="1px" bg={'#00417D33'} borderRadius={'8px'}>
                          <Box>
                            <HStack justify="space-between">
                              <Heading fontSize="xl" mb={2} color={'#00345B'}>Correspondence History</Heading>
                              <HStack>
                                <Text>Show Archived</Text>
                                <Switch size="sm" isChecked={showArchivedCorrespondence} onChange={() => { setShowArchivedCorrespondence(!showArchivedCorrespondence); getCorrespondenceHistory(client.id, !showArchivedCorrespondence); }} />
                              </HStack>
                            </HStack>
                            {client.correspondenceHistory && Array.isArray(client.correspondenceHistory) && client.correspondenceHistory.map((item, index) => (
                              <Accordion key={`${client.name}-correspondence-${index}`} allowMultiple>
                                <AccordionItem>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Text fontWeight={700}>
                                        {item.type === 'call' ? `${formatDate(item.startTime)} - Call` : `${formatDate(item.receivedAt)} - Email`}
                                      </Text>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                  <AccordionPanel pb={4}>
                                  {/* { console.log("Fetched calls:", JSON.stringify(calls, null, 2)) } */}
                                    <List as="ol" spacing={3} styleType="decimal" ml={4}>
                                      {item.type === 'call' && item?.metadata?.summary?.split('. ').map((summary, index) => (

                                      <ListItem key={index}>
                                        {summary}
                                      </ListItem>
                                      ))}
                                    </List>
                                    <BlueBox
                                      actions={item.ActionItem}
                                      client={client}
                                      id={item.id}
                                  type={item.type}
                                  transcriptId={item.id}
                                  onArchive={() => {}}
                                />
                                  </AccordionPanel>

                               
                                </AccordionItem>
                              </Accordion>
                            ))}
                          </Box>
                          {client.aiActionItems && Array.isArray(client.aiActionItems) && (
                            <BlueBox
                              actions={client.aiActionItems}
                              client={client}
                              id={client.id}
                              //onArchive={onArchive}
                            >
                              
                            </BlueBox>
                          )}
                          {(!client.correspondenceHistory || client.correspondenceHistory.length === 0) &&
                            <p>No correspondence history found.</p>
                          }
                          <HStack justify="flex-start">
                            <Button onClick={() => window.open(`${sfUrl}${client?.metadata?.Id}/view`, '_blank')}>
                              <ExternalLinkIcon mr={2} />
                              Salesforce
                            </Button>
                          </HStack>
                          {/* TODO: Implement aiActionItems with BlueBox. Right now I don't differentiate between client.humanActionItems and client.aiActionItems. */}
                      </Box>
                    </Collapse>
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
      <HStack justify="flex-end">
        {page > 1 && <Button onClick={() => handlePageChange(page - 1)}>Previous</Button>}
        {page < totalPages && <Button onClick={() => handlePageChange(page + 1)}>Next</Button>}
        <Text>Page {page} of {totalPages}</Text>
      </HStack>
    </Box>
  );
};

export default Clients;