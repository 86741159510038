import React, { useState } from 'react';
import {
    Select,
    Input,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { useOrganizationClients } from '../contexts/OrganizationClientsContext';

const SearchSelection = ({ onSelect }) => {
    const { clients } = useOrganizationClients();
    const [searchTerm, setSearchTerm] = useState('');

    const options = clients.map(client => ({
        value: client.id,
        label: `${client.firstName} ${client.lastName} (${client.email})`
    }));

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <FormControl>
            {/* <FormLabel>Search and Select</FormLabel> */}
            <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="sm"
            />
            <Select
                placeholder="Select an option"
                onChange={(e) => onSelect(e.target.value)}
                size="sm"
            >
                {filteredOptions.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

export default SearchSelection;