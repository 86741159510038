import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, VStack, Heading, Text, Textarea, Button, useToast, HStack, Table,
  Thead, Tbody, Tr, Th, Td, IconButton, Input, Alert, AlertIcon
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { createOutlookDraft } from '../utils/outlookUtils.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FollowUpPage = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const [followUpEmail, setFollowUpEmail] = useState({
    subject: '',
    summary: '',
    actionItemsClient: '',
    actionItemsAdvisor: ''
  });
  const [originalEmail, setOriginalEmail] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const [discrepancies, setDiscrepancies] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user settings
        const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setUserSettings(settingsResponse.data?.settings || {});

        // Fetch follow-up email
        const response = await axios.post(`${serverUrl}/api/follow-up/${type}/${id}`, 
          { useTemplate: settingsResponse.data?.settings?.useEmailTemplate }, 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );

        if (settingsResponse.data?.settings?.useEmailTemplate) {
          const template = settingsResponse.data?.settings?.emailTemplate || '';
          const today = new Date().toLocaleDateString();
          const filledTemplate = template
            .replace('{{clientName}}', (response.data?.clientInfo?.name || '[Client Name]'))
            .replace('{{todaysDate}}', (today || '[Date]'))
            .replace('{{body}}', (response.data?.followUpEmail.emailBody || '[Email Body]'))
            .replace('{{advisorName}}', `${response.data?.user?.firstName} ${response.data?.user?.lastName}`)
            .replace('{{signatureImage}}', (response.data?.settings?.signatureImage || ''));
          
          setTemplateContent(filledTemplate);
        } else {
          setTemplateContent(response.data?.followUpEmail.emailBody);
        }

        setFollowUpEmail(response.data?.followUpEmail);
        setOriginalEmail(response.data?.followUpEmail);
        setClientInfo(response.data?.clientInfo);
        setIsLoading(false);

        // Check for discrepancies
        const discrepancyResponse = await axios.post(`${serverUrl}/api/${type}/check-discrepancies`, {
          id: id,
          emailContent: response.data?.followUpEmail.emailBody
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setDiscrepancies(discrepancyResponse.data.discrepancies);
      } catch (err) {
        setError('Failed to generate follow-up email');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [type, id, serverUrl]);

  const handleEdit = (field, value) => {
    setFollowUpEmail(prev => ({ ...prev, [field]: value }));
  };

  const handleReset = () => {
    setFollowUpEmail(originalEmail);
  };

  const handleTemplateChange = (content, delta, source, editor) => {
    // Store current selection
    const selection = editor.getSelection();
    
    // Update content with preserved whitespace
    const preservedContent = content.replace(/<p>/g, '<p style="white-space: pre-wrap;">');
    setTemplateContent(preservedContent);

    // Restore selection after state update
    if (selection && quillRef.current) {
      setTimeout(() => {
        const quill = quillRef.current.getEditor();
        quill.setSelection(selection);
      }, 1);
    }
  };

  const renderEmailBodyInput = () => {
    return (
      <Box h="40vh" className="preserve-whitespace">
        <ReactQuill
          ref={quillRef}
          value={templateContent}
          onChange={(content) => setTemplateContent(content)}
          style={{ height: '350px' }}
          modules={{
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            },
            keyboard: {
              bindings: {
                tab: false,
                'indent-0': false
              }
            }
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image'
          ]}
        />
      </Box>
    );
  };

  const handleOpenInOutlook = async () => {
    try {
      const emailContent = userSettings?.useEmailTemplate ? 
        templateContent : 
        followUpEmail.emailBody;

      await axios.post(`${serverUrl}/api/follow-up/${type}/${id}/save`, {
        originalEmail,
        currentDraft: {
          ...followUpEmail,
          emailBody: emailContent
        }
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });

      const { messageData, webLink } = await createOutlookDraft(
        followUpEmail.subject,
        emailContent
      );

      window.open(webLink, '_blank');

      toast({
        title: 'Draft created and opened in Outlook',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating draft in Outlook:', error);
      toast({
        title: 'Failed to create draft in Outlook',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box p={5}>
      {discrepancies && discrepancies !== "No discrepancies found." && (
        <Alert status="warning" mb={5}>
          <AlertIcon />
          <Box>
            <Heading size="sm" mb={2}>Potential Discrepancies Detected:</Heading>
            <Text>{discrepancies}</Text>
          </Box>
        </Alert>
      )}

      <HStack justifyContent="space-between" mb={5}>
        <IconButton
          icon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          aria-label="Go back"
        />
        <Heading size="lg">Follow Up Email to {clientInfo?.name} - {new Date().toLocaleDateString()}</Heading>
        <Button colorScheme="blue" onClick={handleOpenInOutlook}>View Draft in Outlook</Button>
      </HStack>
      
      <VStack spacing={5} align="stretch">
        <Box>
          <Heading size="md" mb={2}>Client Information</Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Gross Income</Th>
                <Th>Net Worth</Th>
                <Th>Age</Th>
                {/* Add more headers as needed */}
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{clientInfo?.grossIncome}</Td>
                <Td>{clientInfo?.netWorth}</Td>
                <Td>{clientInfo?.age}</Td>
                {/* Add more data cells as needed */}
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Box>
          <Heading size="sm" mb={2}>Subject</Heading>
          <Input
            value={followUpEmail.subject}
            onChange={(e) => handleEdit('subject', e.target.value)}
          />
        </Box>

        <Box>
          <Heading size="sm" mb={2}>Email Body</Heading>
          {renderEmailBodyInput()}
        </Box>

        {/*<HStack justifyContent="space-between">
          <Button onClick={handleReset}>
            Reset to Original
          </Button>
        </HStack>*/}
      </VStack>
    </Box>
  );
};

export default FollowUpPage;
