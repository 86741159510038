import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Heading,
    VStack,
    Button,
    Image,
    Grid,
    HStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import Cookies from 'js-cookie';


const Salesforce = () => {
    const [salesforceClients, setSalesforceClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSyncing, setIsSyncing] = useState(false);
    const serverUrl = process.env.REACT_APP_API_URL;
    const itemsPerPage = 100;

    async function getSalesforceClients() {
        try {
            const response = await axios.get(`${serverUrl}/api/salesforce/contacts`, {
                headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch Salesforce clients:', error);
            return [];
        }
    }

    useEffect(() => {
        getSalesforceClients().then(data => setSalesforceClients(data));
    }, []);

    
    return (
        <Box bg="white" p={8}>
            <HStack justify={'space-between'}>
                <Heading color={'#00417D'} fontSize={'32px'}>Salesforce Contacts</Heading>
                <Button
                    isLoading={isSyncing}
                    isDisabled={isSyncing}
                    onClick={async () => {
                        setIsSyncing(true);
                        await axios.post(`${serverUrl}/api/salesforce/sync`, {}, {
                            headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` },
                            withCredentials: true
                        });
                        setIsSyncing(false);
                    }}>Sync</Button>
            </HStack>
            <Box mt={8}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {salesforceClients.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((client, index) => (
                            <Tr key={index}>
                                <Td>{client.Name}</Td>
                                <Td>{client.Email}</Td>
                                <Td>{client.Phone}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <HStack justify={'space-between'} mt={4}>
                    <Button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0}>Previous</Button>
                    <Text>Page {currentPage + 1} of {Math.ceil(salesforceClients.length / itemsPerPage)}</Text>
                    <Button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(salesforceClients.length / itemsPerPage) - 1))} disabled={(currentPage + 1) * itemsPerPage >= salesforceClients.length}>Next</Button>
                </HStack>
            </Box>
        </Box>
    );
};

export default Salesforce;
