import React, { useEffect, useState } from 'react';
import { 
  Box, Heading, Text, VStack, HStack, Flex, IconButton, Progress, Button, 
  Input, Textarea, InputGroup, InputLeftElement, useToast
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlay, FaPause, FaEdit, FaSave, FaTimes } from 'react-icons/fa';

// Define Colors
const colors = {
  primary: "#00417D",
  secondary: "#0053BA",
  accent: "blue.200",
  background: "#F5F5F5",
  text: {
    primary: "#333333",
    secondary: "#555555",
  },
};

// Helper Functions
const formatTime = (seconds) => {
  if (!isFinite(seconds) || isNaN(seconds)) return "00:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

// Helper function to parse timestamp string "hh:mm:ss" into total seconds
const parseTimestamp = (timestamp) => {
  const parts = timestamp.split(':').map(Number);
  if (parts.length !== 3 || parts.some(isNaN)) return 0;
  const [hours, minutes, seconds] = parts;
  return hours * 3600 + minutes * 60 + seconds;
};

const calculateSpeakerStats = (transcription) => {
  const speakerStats = {};

  try {
    transcription.forEach(entry => {
      const { speaker, start, end, words } = entry;
      console.log(`Processing entry for speaker: ${speaker}, start: ${start}, end: ${end}, words: ${words.length}`);

      const duration = end - start;
      console.log(`Calculated duration for speaker ${speaker}: ${duration}`);

      if (!speakerStats[speaker]) {
        speakerStats[speaker] = { totalDuration: 0, totalWords: 0 };
        console.log(`Initialized stats for speaker ${speaker}`);
      }

      speakerStats[speaker].totalDuration += duration;
      speakerStats[speaker].totalWords += words.length; // Each word is counted
      console.log(`Updated stats for speaker ${speaker}: totalDuration = ${speakerStats[speaker].totalDuration}, totalWords = ${speakerStats[speaker].totalWords}`);
    });

    const overallTotalDuration = Object.values(speakerStats).reduce((acc, { totalDuration }) => acc + totalDuration, 0);
    console.log(`Overall total duration: ${overallTotalDuration}`);

    for (const speaker in speakerStats) {
      const { totalDuration, totalWords } = speakerStats[speaker];
      speakerStats[speaker].percentageTime = overallTotalDuration > 0 ? Math.round((totalDuration / overallTotalDuration) * 100) : 0;
      speakerStats[speaker].wpm = totalDuration > 0 ? Math.round((totalWords / totalDuration) * 60) : 0;
      console.log(`Final stats for speaker ${speaker}: percentageTime = ${speakerStats[speaker].percentageTime}, wpm = ${speakerStats[speaker].wpm}`);
    }
  } catch (error) {
    console.error('Error calculating speaker stats:', error);
    // Optionally, you can return an empty object or a default value
    return {};
  }

  return speakerStats;
};

const getTranscriptionDuration = (transcription) => {
  if (!transcription || transcription.length === 0) return 0;
  return Math.max(...transcription.map(entry => entry.end));
};

const Correspondence = () => {
  const { id } = useParams();
  const [callData, setCallData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupedTranscription, setGroupedTranscription] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isEditingSummary, setIsEditingSummary] = useState(false); // Added state for summary editing
  const [newSummary, setNewSummary] = useState(''); // State for summary
  const [newTopics, setNewTopics] = useState([]); // State for topics
  const [duration, setDuration] = useState(0); // State for audio duration
  const [transcriptionDuration, setTranscriptionDuration] = useState(0);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false); // State for transcription editing
  const [editedTranscription, setEditedTranscription] = useState([]); // State for edited transcription
  const serverUrl = process.env.REACT_APP_API_URL;
  const audioRef = React.useRef(null);
  const toast = useToast();

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const response = await axios.get(serverUrl + `/transcripts/${id}`, { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }, withCredentials: true });
        console.log("Received Response: ", response.data);
        // console.log("Topics: ", response.data.call?.topics?.topics || 'No topics available');
        setCallData(response.data);
        setNewTitle(response.data.call.title);
        setNewSummary(response.data.call?.summary ?? ''); 
        setNewTopics(response.data.call?.topics?.topics ?? []); 
        const grouped = groupTranscription(response.data.call.transcription);
        setGroupedTranscription(grouped);
        
        // Add this line to set transcription duration
        setTranscriptionDuration(getTranscriptionDuration(response.data.call.transcription));
      } catch (error) {
        console.error('Error fetching transcript:', error);
        toast({
          title: 'Error',
          description:
            error.response?.data?.error || 'Failed to fetch transcript.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    };
    fetchTranscript();
  }, [id, serverUrl, toast]);

  const groupTranscription = (transcription) => {
    // Since the new format already groups by speaker, we can return it as is
    return transcription.map(entry => ({
      speaker: entry.speaker,
      entries: [entry]
    }));
  };

  const handlePlayPause = () => {
    if (!audioRef.current) return; // Safeguard

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (!audioRef.current) return; // Safeguard

    const currentTime = audioRef.current.currentTime;
    const audioDuration = audioRef.current.duration;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioDuration) && audioDuration > 0 
      ? audioDuration 
      : transcriptionDuration;
    
    setCurrentTime(currentTime);
    
    if (effectiveDuration > 0) {
      const progress = (currentTime / effectiveDuration) * 100;
      setProgress(progress);
      setDuration(effectiveDuration);
    }
  };

  const handleSkip = (seconds) => {
    if (!audioRef.current) return; // Safeguard
    audioRef.current.currentTime += seconds;
  };

  const jumpToTimestamp = (timestamp) => {
    if (!audioRef.current) return; // Safeguard
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const filteredTranscription = groupedTranscription.filter(group =>
    group.entries.some(entry =>
      entry.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.speaker.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (!callData) {
    return <Box>Loading...</Box>;
  }

  const handleSeekClick = (e) => {
    if (!audioRef.current) return; // Safeguard
    const progressBar = e.target;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioRef.current.duration) && audioRef.current.duration > 0 
      ? audioRef.current.duration 
      : transcriptionDuration;
    
    const seekTime = (clickX / progressBar.clientWidth) * effectiveDuration;
    audioRef.current.currentTime = seekTime;
    setProgress((seekTime / effectiveDuration) * 100);
  };

  //Deprecated: Highlighting text at the word level is no longer supported -- causes issues when user edits transcription
  // const getHighlightedText = (entry, groupIndex, entryIndex) => {
  //   return entry.words.map((word, wordIndex) => {
  //     const isHighlighted =
  //       currentTime >= word.start &&
  //       currentTime <= word.end;
  //     const uniqueKey = `${groupIndex}-${entryIndex}-${wordIndex}-${word.start}`;
  //     if (isHighlighted) {
  //       console.log(`Highlighted Word: "${word.text}", Start: ${word.start}, End: ${word.end}, Current Time: ${currentTime}`);
  //     }

  //     return (
  //       <Text
  //         as="span"
  //         key={uniqueKey}
  //         bg={isHighlighted ? colors.accent : 'transparent'}
  //       >
  //         {word.text + ' '}
  //       </Text>
  //     );
  //   });
  // };

  const getHighlightedAndLineBreakedText = (entry, groupIndex, entryIndex) => {
    // Only split on periods followed by capital letters
    const sentences = entry.text
      .split(/(?<=\.)\s+(?=[A-Z])/)
      .filter(sentence => sentence.trim());
    
    const formattedText = sentences.map((sentence, index) => (
      <React.Fragment key={index}>
        {index > 0 && index % 4 === 0 && <><br /><br /></>}
        {sentence.trim()}{' '}
      </React.Fragment>
    ));
    
    // Determine if the entire utterance should be highlighted
    const isHighlighted = currentTime >= entry.start && currentTime <= entry.end;
    const uniqueKey = `${groupIndex}-${entryIndex}-${entry.start}`;
  
    if (isHighlighted) {
      console.log(`Highlighted Utterance: "${entry.text}", Start: ${entry.start}, End: ${entry.end}, Current Time: ${currentTime}`);
    }
  
    return (
      <Text
        as="span"
        key={uniqueKey}
        bg={isHighlighted ? colors.accent : 'transparent'}
      >
        {formattedText}
      </Text>
    );
  };

  // Event Handlers for Title Editing
  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(callData.call.title);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: 'Validation Error',
        description: 'Title cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${callData.call.id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        }
      );
      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          title: response.data.call.title,
        },
      }));
      setIsEditingTitle(false);
      toast({
        title: 'Title Updated',
        description: 'The call title has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: 'Update Failed',
        description: 'There was an error updating the title.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Summary Editing
  const handleEditSummary = () => {
    setIsEditingSummary(true);
  };

  const handleCancelEditSummary = () => {
    setIsEditingSummary(false);
    setNewSummary(callData.call.summary ?? '');
  };

  const handleSaveSummary = async () => {
    if (newSummary.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Summary cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedSummary = newSummary.trim();

      const response = await axios.put(
        `${serverUrl}/update-summary/${callData.call.id}`,
        { summary: formattedSummary },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          summary: response.data.summary,
        },
      }));

      setIsEditingSummary(false);
      toast({
        title: "Summary Updated",
        description: "The summary has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating summary:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the summary.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Transcription Editing
  const handleEditTranscription = () => {
    setEditedTranscription(groupedTranscription.map(group => ({
      ...group,
      editedText: group.entries.map(entry => entry.text).join(' ')
    })));
    setIsEditingTranscription(true);
  };

  const handleCancelEditTranscription = () => {
    setIsEditingTranscription(false);
    setEditedTranscription([]);
  };

  const handleTranscriptionChange = (index, newText) => {
    const updatedTranscription = [...editedTranscription];
    updatedTranscription[index].editedText = newText;
    setEditedTranscription(updatedTranscription);
  };

  const handleSaveTranscription = async () => {
    try {
      // Prepare the updated transcription array
      const updatedTranscriptionArray = editedTranscription.flatMap((group, groupIndex) => {
        // Split the edited text into words
        const editedWords = group.editedText.split(/\s+/).filter(text => text);
  
        // Get the original words for this group to preserve timings
        const originalGroup = callData.call.transcription[groupIndex];
        const originalWords = originalGroup.words || [];
  
        // Map edited words to original word timestamps
        const words = editedWords.map((text, wordIndex) => {
          const originalWord = originalWords[wordIndex];
          if (originalWord) {
            return {
              text,
              speaker: group.speaker,
              start: originalWord.start,
              end: originalWord.end,
              confidence: originalWord.confidence
            };
          } else {
            // Fallback: Assign approximate timings if original word is unavailable
            const lastEnd = originalWords[wordIndex - 1]?.end || group.start;
            return {
              text,
              speaker: group.speaker,
              start: lastEnd,
              end: lastEnd, // Assign a default duration of 0 seconds
              confidence: 1.0 // Assuming full confidence for added words
            };
          }
        });
  
        return [{
          speaker: group.speaker,
          start: originalGroup.start,
          end: originalGroup.end,
          text: editedWords.join(' '),
          words: words
        }];
      });

      // Send the updated transcription to the server
      const response = await axios.put(
        `${serverUrl}/update-transcript/${callData.call.id}`,
        { transcript: updatedTranscriptionArray },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Update the callData and groupedTranscription with the new transcription
      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          transcription: response.data.call.transcription,
        },
      }));
      setGroupedTranscription(groupTranscription(response.data.call.transcription));
      setIsEditingTranscription(false);
      setEditedTranscription([]);
      toast({
        title: 'Transcription Updated',
        description: 'The transcription has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating transcription:', error);
      toast({
        title: 'Update Failed',
        description: 'There was an error updating the transcription.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const speakerStats = calculateSpeakerStats(callData.call.transcription);

  return (
    <Box bg="white" h="100%" p={8} pb={24} overflowY="auto">
      {/* <Container maxW="container.lg"> */}
        <IconButton
          aria-label="Go back"
          icon={<ArrowBackIcon />}
          onClick={() => window.history.back()}
          mb={4}
          variant="ghost"
          color="gray.600"
          _hover={{ bg: "gray.100" }}
        />
        <VStack align="stretch" spacing={6}>

          {/* Editable Title Section */}
          <Box position="relative" role="group">
            {isEditingTitle ? (
              <VStack align="start" spacing={2}>
                <Input
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Enter new title"
                  size="lg"
                  borderColor={colors.primary}
                  _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                />
                <HStack spacing={2}>
                  <Button size="sm" colorScheme="green" onClick={handleSaveTitle} leftIcon={<FaSave />}>
                    Save
                  </Button>
                  <Button size="sm" colorScheme="red" onClick={handleCancelEditTitle} leftIcon={<FaTimes />}>
                    Cancel
                  </Button>
                </HStack>
              </VStack>
            ) : (
              <HStack align="center" spacing={2}>
                <Heading color={colors.primary} fontSize={{ base: '28px', md: '36px' }}>
                  {callData.call.title}
                </Heading>
                <IconButton
                  aria-label="Edit Title"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditTitle}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              </HStack>
            )}
          </Box>

          {/* Editable Summary Section */}
          <Box position="relative" role="group">
            <HStack align="center" spacing={2}>
              <Heading size="md" mb={2} color={colors.primary}>
                Summary
              </Heading>
              {!isEditingSummary ? (
                <IconButton
                  aria-label="Edit Summary"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditSummary}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              ) : (
                <>
                  <IconButton
                    aria-label="Save Summary"
                    icon={<FaSave />}
                    size="sm"
                    onClick={handleSaveSummary}
                    colorScheme="green"
                    variant="ghost"
                  />
                  <IconButton
                    aria-label="Cancel Summary Edit"
                    icon={<FaTimes />}
                    size="sm"
                    onClick={handleCancelEditSummary}
                    colorScheme="red"
                    variant="ghost"
                  />
                </>
              )}
            </HStack>
            <VStack align="start" spacing={2}>
              <Textarea
                value={newSummary}
                onChange={(e) => setNewSummary(e.target.value)}
                placeholder="Enter new summary"
                size="md"
                borderColor={colors.primary}
                _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                resize="vertical"
                fontSize={{ base: '16px', md: '18px' }}
                color={colors.text.primary}
                lineHeight="1.5"
                height={{ base: "120px", md: "150px" }}
                display={isEditingSummary ? 'block' : 'none'}
              />
              {!isEditingSummary && (
                <Text
                  fontSize={{ base: '16px', md: '18px' }}
                  color={colors.text.secondary}
                  whiteSpace="pre-wrap"
                  lineHeight="1.5"
                >
                  {newSummary || "No summary was created for this transcript."}
                </Text>
              )}
            </VStack>
          </Box>

          {/* Search Bar */}
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search transcript"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              borderColor={colors.primary}
              _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
            />
          </InputGroup>
          
          {/* Call Topics */}
          <Box>
            <Heading size="md" mb={2} color={colors.primary}>
              Topics
            </Heading>
            {newTopics.length > 0 ? (
              <VStack align="start" spacing={1}>
                {newTopics.map((topic, index) => (
                  <HStack key={index}>
                    <Text fontSize={{ base: '16px', md: '18px' }} color={colors.text.primary}>
                      {topic.topic}
                    </Text>
                    <Button
                      variant="link"
                      color="blue.500"
                      onClick={() =>
                        jumpToTimestamp(parseTimestamp(topic.timestamp))
                      }
                      size="sm"
                      leftIcon={<FaPlay />}
                    >
                      {formatTime(parseTimestamp(topic.timestamp))}
                    </Button>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <Text fontSize={{ base: '16px', md: '18px' }} color={colors.text.secondary}>
                No topics were created for this transcript.
              </Text>
            )}
          </Box>

          {/* Speaker Statistics */}
          <Box>
            <Heading size="md" mb={2} color={colors.primary}>
              Speaker Statistics
            </Heading>
            <VStack align="start" spacing={1}>
              {Object.entries(speakerStats).map(([speaker, stats]) => (
                <Text key={speaker} fontSize={{ base: '16px', md: '18px' }} color={colors.text.secondary}>
                  <strong>{speaker}:</strong> {stats.percentageTime}% of time spoken, {stats.wpm} WPM
                </Text>
              ))}
            </VStack>
          </Box>

          {/* Editable Transcription Section */}
          <Box position="relative" role="group">
            <HStack align="center" spacing={2}>
              <Heading size="md" mb={2} color={colors.primary}>
                Transcription
              </Heading>
              {!isEditingTranscription ? (
                <IconButton
                  aria-label="Edit Transcription"
                  icon={<FaEdit />}
                  size="sm"
                  onClick={handleEditTranscription}
                  variant="ghost"
                  colorScheme="blue"
                  _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                  transition="background-color 0.3s ease, transform 0.2s ease"
                />
              ) : (
                <>
                  <IconButton
                    aria-label="Save Transcription"
                    icon={<FaSave />}
                    size="sm"
                    onClick={handleSaveTranscription}
                    colorScheme="green"
                    variant="ghost"
                  />
                  <IconButton
                    aria-label="Cancel Transcription Edit"
                    icon={<FaTimes />}
                    size="sm"
                    onClick={handleCancelEditTranscription}
                    colorScheme="red"
                    variant="ghost"
                  />
                </>
              )}
            </HStack>
            {isEditingTranscription ? (
              <VStack align="stretch" spacing={4} mt={4}>
                {editedTranscription.map((group, index) => (
                  <Box key={index}>
                    <Heading size="sm" color={colors.text.primary}>
                      {group.speaker}
                    </Heading>
                    <Textarea
                      value={group.editedText}
                      onChange={(e) => handleTranscriptionChange(index, e.target.value)}
                      placeholder={`Edit transcription for ${group.speaker}`}
                      size="md"
                      borderColor={colors.primary}
                      _focus={{ borderColor: colors.secondary, boxShadow: 'outline' }}
                      resize="vertical"
                      fontSize={{ base: '16px', md: '18px' }}
                      color={colors.text.primary}
                      lineHeight="1.5"
                      minHeight="100px"
                    />
                  </Box>
                ))}
              </VStack>
            ) : (
              <Box>
                {filteredTranscription.map((group, groupIndex) => (
                  <Flex
                    key={groupIndex}
                    mt={2}
                    align="start"
                    p={3}
                    borderRadius="md"
                    boxShadow="sm"
                    transition="background-color 0.3s ease, box-shadow 0.3s ease"
                    _hover={{ bg: colors.accent, boxShadow: 'md' }}
                  >
                    <Box flex="1">
                      <Text as="span" fontWeight="bold" color={colors.primary}>
                        {group.speaker}:
                      </Text>
                      <Text as="span" ml={2}>
                        {group.entries.map((entry, entryIndex) => getHighlightedAndLineBreakedText(entry, groupIndex, entryIndex))}
                      </Text>
                    </Box>
                    <Button 
                      size="xs" 
                      ml={2} 
                      mt={1}
                      variant="link"
                      color="blue.500"
                      onClick={() => jumpToTimestamp(group.entries[0].start)}
                    >
                      {formatTime(group.entries[0].start)}
                    </Button>
                  </Flex>
                ))}
              </Box>
            )}
          </Box>
        </VStack>
      {/* </Container> */}

      {/* Audio Player */}
      <Box
        position="fixed"
        bottom={0}
        left={{ base: 0, md: 242 }}  
        right={{ base: 0, md: 8 }}
        bg={colors.background}
        color={colors.primary}
        p={4}
        boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
        zIndex={1000}
      >
        <Flex align="center" justify="space-between">
          <HStack spacing={4}>
            <IconButton
              icon={isPlaying ? <FaPause /> : <FaPlay />}
              onClick={handlePlayPause}
              aria-label={isPlaying ? "Pause" : "Play"}
              colorScheme="blue"
              variant="ghost"
              size="lg"
            />
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handleSkip(-10)}
              aria-label="Rewind 10 seconds"
              variant="ghost"
            />
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handleSkip(10)}
              aria-label="Forward 10 seconds"
              variant="ghost"
            />
            <IconButton
              icon={<RepeatIcon />}
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime = 0;
                  audioRef.current.play();
                }
              }}
              aria-label="Restart"
              variant="ghost"
            />
          </HStack>
          <Progress
            value={progress}
            flex={1}
            size="sm"
            colorScheme="blue"
            onClick={handleSeekClick}
            sx={{ cursor: 'pointer', mx: 4 }}
            borderRadius="md"
          />
          <Text fontSize="sm">
            {formatTime(currentTime)} / {formatTime(isFinite(duration) ? duration : transcriptionDuration)}
          </Text>
        </Flex>
        <audio
          ref={audioRef}
          src={callData.audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={() => {
            if (audioRef.current) {
              setDuration(audioRef.current.duration);
            }
          }}
          onEnded={() => setIsPlaying(false)}
          style={{ display: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default Correspondence;