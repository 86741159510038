import React, { useEffect, useState } from 'react';
import {
  Box, Collapse, Heading, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure,
  VStack,
  HStack,
  Button,
  useToast,
  Switch,
  Input,
  Image,
  Grid,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react';
import BlueBox from '../../BlueBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const fetchEvents = async (setEvents) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  console.log('Fetching events...');
  console.log(`response endpoint:${serverUrl}/api/events`);
  try {
    const response = await axios.get(`${serverUrl}/api/events`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}` // Include the token in the request headers
      }
    });
    console.log('Fetched Meetings:', response.data); // Log the fetched meetings
    setEvents(response.data); 
  } catch (error) {
    console.error('Error fetching meetings:', error);
  }
};

const formatDate = (dateString, timeZone) => {
  const localDate = new Date(dateString); // Create Date object from the ISO string
  const options = { 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true,
      timeZone: timeZone // Use the provided time zone
  };
  return localDate.toLocaleString('en-US', options); // Format according to the user's time zone
};

const Meetings = () => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); 
  const [events, setEvents] = useState([]);
  const [webConferenceUrl, setWebConferenceUrl] = useState('');
  const [botId, setBotId] = useState(null); 
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Automatically get the user's time zone

  const toast = useToast();

  useEffect(() => {
    fetchEvents(setEvents);
  }, []); 

  const handleLiveCall = async () => {
    if (webConferenceUrl) {
      try {
        const response = await axios.post(`${serverUrl}/api/createBot`, 
          { meetingUrl: webConferenceUrl }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            },
          }
        );
        console.log('handleLiveCall response data:', response.data);
        const createdBotId = response.data.botId; // Assuming the response contains the botId
        setBotId(createdBotId); // Store the botId in state
        console.log('Created bot with ID:', createdBotId);
        toast({ 
          title: 'DataDasher Notetaker',
          description: 'The DataDasher Notetaker will join your meeting in a few minutes.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error creating bot:', error.response ? error.response.data : error.message);
      }
    } else {
      console.error('Please enter a valid web conference URL.');
    }
  };

  const now = new Date(); // Store the current date
  const filteredEvents = events
    .filter(event => {
      const eventStartTime = new Date(event.startTime);
      return eventStartTime >= now; // Include events that start now
    });

  // filteredEvents.forEach((event, index) => {
  //   console.log(`Event ${index}: start time as Date:`, new Date(event.startTime));
  // });
  // console.log('filteredEvents:', filteredEvents);

  // Determine the number of columns based on screen size for ActionBoxes
  const actionGridColumns = useBreakpointValue({ base: 1, sm: 2, md: 3 });

  return (
    <Box bg="white" p={8}>
      <Heading color={'#00417D'} fontSize={{ base: '24px', md: '32px' }}>Meetings</Heading>
      
      <Grid templateColumns={`repeat(${actionGridColumns}, 1fr)`} gap={6} mt={8}>
        <ActionBox 
          image={<Image src="/images/Outlook.png" alt="Outlook" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description={
            <>
              Connect your Outlook calendar to enable DataDasher to automatically join your scheduled meetings.
              <br />
              <br />
              DataDasher will join scheduled meetings on any of the following platforms: Zoom, Google Meet, Webex, or Microsoft Teams.
            </>
          }
          buttonText="Integrations" 
          buttonAction={() => navigate('/integrations')} 
        />
        {/* <ActionBox 
          image={<Image src="/images/DD_logo.png" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description="Invite dev@datadasher.ai to join your scheduled meetings." 
          description2={<Input w={'200px'} mt={4} placeholder="dev@datadasher.ai" bg={'white'} _placeholder={{ color: 'black' }} h={'50px'} />} 
          buttonText="Copy" 
          buttonAction={() => { }} 
        /> */}
        <ActionBox 
          image={<Image src="/images/DD_logo.png" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description={
            <>
              Record in person meetings live using our webapp. 
              <br />
              <br />
              These meetings are automatically synced to the desktop app.
            </>
          } 
          buttonText="Record" 
          buttonAction={() => navigate('/live-meeting')}
        />
        <ActionBox 
          image={<Image src="/images/DD_logo.png" alt="DD Logo" boxSize={{ base: '80px', md: '100px' }} objectFit="contain"/>} 
          description={
            <>
              Enter your web conference link to have DataDasher join your call.
              <br />
              <br />
            </>
          } 
          description2={
            <Input 
              value={webConferenceUrl} 
              onChange={(e) => setWebConferenceUrl(e.target.value)} 
              placeholder="Enter web conference link" 
              mt={2} 
              size="md"
            />
          } 
          buttonText="Join Live Call" 
          buttonAction={handleLiveCall} 
        />
      </Grid>

      <Heading color={'black'} fontSize={{ base: '20px', md: '24px' }} mt={8}>Upcoming Meetings</Heading>
      <Text color={'gray'} fontSize={{ base: '14px', md: '16px' }} mt={4}>
        Events from your Outlook Calendar for the next 30 days will be displayed here.
      </Text>
      <VStack alignItems={'start'} mt={4} spacing={4}>
        {/* <HStack 
          justifyContent="space-between" 
          w="90%" 
          px={{ base: 2, md: 0 }}
        >  
          <Text fontSize={{ base: '14px', md: '16px' }}>Meeting Title</Text>
          <Text fontSize={{ base: '14px', md: '16px' }}>Meeting Date and Location</Text>
          <Text fontSize={{ base: '14px', md: '16px' }}>DataDasher Join Status</Text>
        </HStack> */}
        {filteredEvents.length === 0 ? ( // Use filtered events to check if there are no events
          <Text fontSize={{ base: '14px', md: '16px' }}>No events found.</Text>
        ) : (
          filteredEvents
          .sort((a, b) => new Date(a.startTime) - new Date(b.startTime)) // Sort events by startTime, recent first
          .map((event) => {
            const unformattedStartTime = event.startTime; // Unformatted start time
            const unformattedEndTime = event.endTime; // Unformatted end time
            const formattedStartTime = formatDate(unformattedStartTime, userTimeZone); // Pass the user's time zone
            const formattedEndTime = formatDate(unformattedEndTime, userTimeZone); // Pass the user's time zone
            return (
              <EventBox 
                key={event.id}
                eventId={event.id} 
                subject={event.subject} 
                startTime={formattedStartTime} 
                endTime={formattedEndTime} 
                webConfLink={event.webConfLink}
                location={event.location}
                audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                botId={event.botId ?? null}
              />
            );
          })
        )}
      </VStack>
    </Box>
  );
};

const ActionBox = ({ image, description, description2, buttonText, buttonAction }) => {
  return (
    <Box 
      bg="#F5F5F5" 
      pt={{ base: 6, md: 8 }} 
      pl={{ base: 6, md: 8 }} 
      pr={{ base: 6, md: 8 }} 
      pb={{ base: 6, md: 8 }} 
      w="100%" 
      minHeight={{ base: '300px', md: '350px' }} 
      borderRadius="8px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      display="flex" 
      flexDirection="column" 
      justifyContent="space-between"
      _hover={{ boxShadow: 'md' }}
      transition="box-shadow 0.3s ease"
    >
      <VStack textAlign="center" spacing={4}>
        {image}
        <Text 
          mt={4} 
          fontSize={{ base: '14px', md: '16px' }} 
          wordBreak="break-word"
        >
        {description}
        </Text>
        {description2}
      </VStack>
      <Button 
        mt={5}
        onClick={buttonAction} 
        bg="#A3BBD0" 
        w={{ base: '120px', md: '150px' }} 
        h={{ base: '40px', md: '50px' }} 
        alignSelf="center"
      >
        <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }}>{buttonText}</Text>
      </Button>
    </Box>
  );
};

export const EventBox = ({
  eventId,
  subject,
  startTime,
  endTime,
  webConfLink,
  location,
  audioBotJoinStatus,
  botId
}) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [isChecked, setIsChecked] = useState(audioBotJoinStatus);

  const handleToggle = async () => {
    const newAttendingStatus = !isChecked;
    setIsChecked(newAttendingStatus);

    try {
      await axios.patch(`${serverUrl}/api/events/${eventId}`, {
        botId: botId, // Update the botId field
        audioBotJoinStatus: newAttendingStatus // Update the audioBotJoinStatus field
      });
      console.log('Attendance status updated:', newAttendingStatus);
    } catch (error) {
      console.error('Error updating attendance status:', error);
    }
  };

  const handleLiveCall = () => {
    window.location.href = `${serverUrl}/live-meeting`;
  };

  return (
    <Box
      bg="#F5F5F5"
      p={4}
      borderRadius="8px"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      transition="box-shadow 0.3s ease"
      _hover={{ boxShadow: 'md' }}
      w="90%"
      mb={4}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        spacing={{ base: 2, md: 4 }}
      >
        <VStack align="start" spacing={1}>
          <Tooltip label="Meeting Subject" aria-label="Meeting Subject Tooltip">
            <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }}>
              {subject}
            </Text>
          </Tooltip>
          <Tooltip
            label="Meeting Time"
            aria-label="Meeting Time Tooltip"
          >
            <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
              {startTime} - {endTime}
            </Text>
          </Tooltip>
          {webConfLink ? (
            <Tooltip
              label="Join the web conference"
              aria-label="Web Conference Link Tooltip"
            >
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="blue.500"
                isTruncated
              >
                <a href={webConfLink} target="_blank" rel="noopener noreferrer">
                  {webConfLink}
                </a>
              </Text>
            </Tooltip>
          ) : (
            <Tooltip
              label="Meeting Location"
              aria-label="Meeting Location Tooltip"
            >
              <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
                {location}
              </Text>
            </Tooltip>
          )}
        </VStack>
        <VStack>
          {webConfLink ? (
            <Tooltip
              label={
                isChecked
                  ? 'DataDasher will join the call'
                  : 'DataDasher will not join the call'
              }
              aria-label="DataDasher Join Status Tooltip"
            >
              <Switch
                colorScheme="blue"
                isChecked={isChecked}
                onChange={handleToggle}
                size="lg"
                transition="all 0.2s ease"
                aria-label="Toggle DataDasher Join Status"
              />
            </Tooltip>
          ) : (
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={handleLiveCall}
              bg="#00417D"
              size={{ base: 'sm', md: 'md' }}
              transition="background-color 0.3s ease"
            >
              Create Live Call
            </Button>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};  

export { fetchEvents, formatDate };
export default Meetings;