import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkAuth = async () => {
      const currentPath = window.location.pathname;
      if (currentPath === '/login' || currentPath === '/signup') {
        setLoading(false);
        return;
      }
      try {
        console.log('Server URL:', serverUrl);
        const token = Cookies.get('jwtToken');
        // console.log('Token:', token);
        const response = await axios.get(`${serverUrl}/auth/check`, { withCredentials: true });
        // console.log('Response:', response);
        console.log('Response data:', response.data.isAuthenticated);
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        setIsAuthenticated(false);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [serverUrl, navigate]);

  const login = async (email, password) => {
    try {
      await axios.post(`${serverUrl}/login`, { email, password }, { withCredentials: true });
      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    }
  };

  const logout = async () => {
    Cookies.set('jwtToken', '', { path: '/' });
    console.log('Removing token');
    console.log('Token:', Cookies.get('jwtToken'), { path: '/' });
    setIsAuthenticated(false);
    await axios.post(`${serverUrl}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);