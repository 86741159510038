import React, { useEffect, useRef } from 'react';
import { continuousVisualizer } from 'sound-visualizer';

function VisualizerTest() {
    const canvasRef = useRef(null);
    const visualizerRef = useRef(null);

    useEffect(() => {
        const setupVisualizer = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                if (canvasRef.current) {
                    visualizerRef.current = continuousVisualizer(stream, canvasRef.current, {
                        strokeColor: '#ff0000',
                        lineWidth: 2,
                    });
                    visualizerRef.current.start();
                    console.log('Test Visualizer started');
                }
            } catch (err) {
                console.error('Visualizer Test Error:', err);
            }
        };

        setupVisualizer();

        return () => {
            if (visualizerRef.current) {
                visualizerRef.current.stop();
                visualizerRef.current.reset();
            }
        };
    }, []);

    return (
        <div>
            <h2>Visualizer Test</h2>
            <canvas
                ref={canvasRef}
                width={600}
                height={100}
                style={{ border: '1px solid #ccc', borderRadius: '8px' }}
            ></canvas>
        </div>
    );
}

export default VisualizerTest;