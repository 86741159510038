import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const OrganizationClientsContext = createContext();

export const OrganizationClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/clients-in-organization`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching organization clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [serverUrl]);

  return (
    <OrganizationClientsContext.Provider value={{ clients, loading, setClients }}>
      {children}
    </OrganizationClientsContext.Provider>
  );
};

export const useOrganizationClients = () => {
  const context = useContext(OrganizationClientsContext);
  if (context === undefined) {
    throw new Error('useOrganizationClients must be used within an OrganizationClientsProvider');
  }
  return context;
}; 