import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Switch, HStack, VStack, Text, Spinner, Select, Button } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { split } from 'sentence-splitter';
import { fetchEvents, EventBox, formatDate } from '../Meetings/Meetings';

const Home = ({scrollRef}) => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const [myFeed, setMyFeed] = useState([]);
    const [feed, setFeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewingTranscript, setViewingTranscript] = useState(false);
    const [resyncLoading, setResyncLoading] = useState(true);
    const [sortOption, setSortOption] = useState('dateAsc');
    const [filterOption, setFilterOption] = useState(() => {
        return localStorage.getItem('filterOption') || 'emails and calls';
    });
    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
      setLoading(true);
      axios.get(`${serverUrl}/api/my-feed`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      })
        .then(response => {
          console.log('My Feed:', response.data);
          return response.data;
        })
        .then(data => {
          console.log('Action Items:', data);
          const sortedData = sortFeed([...data], sortOption);
          console.log('Sorted Data:', sortedData);
          setMyFeed([...sortedData]);
          setFeed([...sortedData]);
          setLoading(false);

        });
    }, [serverUrl]);

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed?showArchived=${showArchived}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        return response.data;
      })
      .then(data => {
        console.log('Archived Correspondences:', data);
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
          const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
          return dateB - dateA; // Descending order
        });
        setMyFeed([...sortedData]);
        setLoading(false);
      });
  }

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };
  
    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef, filterOption]); // Include scrollRef in the dependency array

  const handleArchive = (itemId) => {
      console.log('Archive', itemId);
      // Update the state to remove the archived item
      let newFeed = myFeed.filter(item => item.id !== itemId);
      setMyFeed([...newFeed] );
  }

  // Function to split summary into sentences using sentence-splitter
  const getListItems = (summary) => {
    if (!summary) return [];

    const sentences = split(summary).filter(
      (element) => element.type === 'Sentence'
    );

    // Extract and trim each sentence
    return sentences.map((sentence) => sentence.raw.trim());
  };

    const sortFeed = (feedToSort, option = sortOption) => {
        return feedToSort.sort((a, b) => {
            const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
            const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
            if (option === 'dateDesc') {
                return dateA - dateB;
            } else if (option === 'dateAsc') {
                return dateB - dateA;
            }
            // Add more sorting options if needed
            return 0;
        });
    };

    const filterFeed = (feed) => {
        if (filterOption === 'emails and calls') return feed;
        if (filterOption === 'today') return [];
        return feed.filter(item => item.correspondenceType === filterOption);
    };

    const handleSortChange = (e) => {
      const selectedOption = e.target.value;
      setSortOption(selectedOption);

      const sortedMyFeed = sortFeed([...myFeed], selectedOption);
      setMyFeed(sortedMyFeed);

      const sortedEvents = sortFeed([...events], selectedOption);
      setEvents(sortedEvents);
  };

    const handleFilterChange = (e) => {
        const selectedOption = e.target.value;
        setFilterOption(selectedOption);
    };  

    const refreshFeed = useCallback(() => {
        setLoading(true);
        axios.get(`${serverUrl}/api/my-feed`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        })
        .then(response => {
            console.log('Refreshed My Feed:', response.data);
            const sortedData = sortFeed(response.data);
            setMyFeed(sortedData);
            setFeed(sortedData);
            setLoading(false);
        })
        .catch(error => {
            console.error('Error refreshing feed:', error);
            setLoading(false);
        });
    }, [serverUrl, sortOption]);

    // Apply filtering when filterOption changes
    useEffect(() => {
        const filteredFeed = filterFeed(feed);
        setMyFeed(filteredFeed);
    }, [filterOption]);

    // Updated filteredEvents to show events on the same calendar day
    const filteredEvents = events.filter(event => {
      const eventDate = new Date(event.startTime);
      const today = new Date();
      return (
          eventDate.getFullYear() === today.getFullYear() &&
          eventDate.getMonth() === today.getMonth() &&
          eventDate.getDate() === today.getDate()
      );
    });
    
    // Persist filterOption to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('filterOption', filterOption);
        
        if (filterOption === 'today') {
            fetchEvents(setEvents);
        }
    }, [filterOption]);

    return (
        <Box bg="white" p={8} overflowY="auto">
          <Heading color={'#00417D'} pb={4}>My Feed</Heading>
          <HStack justify="space-between" mb={4}>
            <HStack>
              <Select value={sortOption} onChange={handleSortChange} w={200}>
                <option value="dateAsc">Newest First</option>
                <option value="dateDesc">Oldest First</option>
              </Select>
              <Select value={filterOption} onChange={handleFilterChange} w={200}>
                <option value="today">Today's View</option>
                <option value="emails and calls">Emails and Calls</option>
                <option value="email">Emails</option>
                <option value="call">Calls</option>
              </Select>
            </HStack>
            <HStack>
              <Text>View Archived Correspondences</Text>
              <Switch
                onChange={(e) => {
                  const showArchived = e.target.checked;
                  handleShowArchived(showArchived);
                }}
              />
            </HStack>
          </HStack>
          {loading && <Spinner />}
          {!loading && filterOption !== 'today' && filterFeed(myFeed).map((data, index) => (  
            <Box pr={20} key={data.id}>  
              <GrayBox
                key={data.id}  // Ensure key prop is unique
                imageSrc={data?.imageSrc}
                title={
                  data.title
                    ? data.title
                    : data.correspondenceType === "email"
                    ? `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")}${
                        data.metadata.subject ? ` - ${data.metadata.subject}` : ""
                      } ${new Date(
                        data?.metadata?.sentDateTime || data.receivedAt
                      ).toLocaleString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                    : data.correspondenceType === "call"
                    ? `${data?.title ? data.title : `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")} ${
                        data?.event ? data.event.subject : ""
                      } ${
                        data?.event
                          ? new Date(data.event.startTime).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })
                          : new Date(data.startTime).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })
                      }`}`
                    : `${data?.clients
                        .map((client) => `${client.firstName} ${client.lastName}`)
                        .join(", ")} ${new Date(
                        data?.startTime
                      ).toLocaleString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                }
                subtitle={data?.correspondenceType.charAt(0).toUpperCase() + data?.correspondenceType.slice(1)}
                listItems={
                  data?.correspondenceType === 'email'
                    ? getListItems(data?.metadata?.summary) || []
                    : data?.correspondenceType === 'call'
                    ? getListItems(data?.summary) || []
                    : [] // Default case if neither 'email' nor 'call'
                }
                rows={data?.ActionItem} 
                transcriptId={data?.id}
                client={data?.clients}
                type={data?.correspondenceType}
                id={data?.id}
                onArchive={() => handleArchive(data.id)} // Pass the archive handler
                scrollRef={scrollRef}
                errorItems={data?.ActionItem.filter(data => data.aiCompletionStatus === 'ERROR')} 
                correspondence={data}
                nonClientSpeakers={data?.nonClientSpeakers}
                resyncLoading={resyncLoading}
                fetchJobs={fetchJobs}
                refreshFeed={refreshFeed}
              />
            </Box>
          ))}
      {/* Today Only View */}
      {filterOption === 'today' && (
        <>
          <Text color={'gray'} fontSize={'16px'} mt={4}>Events from your Outlook Calendar for today will be displayed here.</Text>
          {eventsLoading ? (
            <Spinner />
          ) : filteredEvents.length > 0 ? (
            <>
              {/* Header for Today's Events */}
              {/* <VStack alignItems={'start'} mt={4}>
                <HStack justifyContent="space-between" w="90%">
                  <Text>Meeting Title</Text>
                  <Text>Meeting Date and Location</Text>
                  <Text>DataDasher Join Status</Text>
                </HStack>
              </VStack>
              <Box height="10px" /> */}
              {/* List of Today's Events */}
              <VStack spacing={4} mt={4} align="stretch">
                {filteredEvents
                  .map(event => (
                    <EventBox
                      key={event.id}
                      eventId={event.id}
                      subject={event.subject}
                      startTime={formatDate(event.startTime, userTimeZone)}
                      endTime={formatDate(event.endTime, userTimeZone)}
                      webConfLink={event.webConfLink}
                      location={event.location}
                      audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                      botId={event.botId ?? null}
                    />
                  ))}
              </VStack>
            </>
          ) : (
            <Text>No events for today.</Text>
          )}
        </>
      )}
        </Box>
      );
    };
    
    export default Home;
