import React from 'react';
import { Box, HStack, VStack, Switch, Button, Icon, Tooltip, useToast, Text, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import RowItem from './RowItem';
import ActionButton from './ActionButton';
import { FiCheckCircle, FiUser, FiExternalLink } from 'react-icons/fi';
import { CheckIcon, CloseIcon, CheckCircleIcon, ExternalLinkIcon, ViewOffIcon, ViewIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { IoMdHappy, IoMdSad } from 'react-icons/io';
import { FaRegUser, FaRegEnvelope } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../contexts/UserContext';

const BlueBox = ({ actions, transcriptId, client, id, type, scrollRef, onArchive, correspondence }) => {
  const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/'
  const toast = useToast();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const serverUrl = process.env.REACT_APP_API_URL;
  const [actionItems, setActionItems] = useState(actions);
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const [stateTranscriptId, setStateTranscriptId] = useState(transcriptId);
  const [stateClient, setStateClient] = useState(client);
  const [stateId, setStateId] = useState(id);
  const [stateType, setStateType] = useState(type);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const { user } = useUser(); // Use the global user context
  const [userCrms, setUserCrms] = useState(user?.organization?.crms || []); // State to hold user CRMs
  const [assignedUsers, setAssignedUsers] = useState({}); // { actionItemId: userName }

  useEffect(() => {
    setStateTranscriptId(transcriptId);
    setStateClient(client);
    setStateId(id);
    setStateType(type);
    setStateCorrespondence(correspondence);
  }, [transcriptId, client, id, type, onArchive]);

  useEffect(() => {
    // console.log(actions);
    setActionItems(actions);
  }, [actions]);

  const handleApprove = (item) => {
    // post this to the server /api/actionItems/:id/approve
    axios.post(`${serverUrl}/api/actionItems/${item.id}/approve`, {
      userId: assignedUsers[item.id]?.id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      // set the aiCompletionStatus to SUCCESS
      setActionItems(actionItems.map(i => i.id === item.id ? { ...i, aiCompletionStatus: 'SUCCESS' } : i));
    }).catch(error => {
      console.error('Error approving action item:', error);
      toast({
        title: "Error",
        description: `There was an error approving the action item: ${error.response?.data?.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
  }

  const handleArchive = (item) => {
    console.log('Archive');
    axios.post(`${serverUrl}/api/action-items/${item.id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchive = (item) => {
    console.log('Unarchive');
    axios.post(`${serverUrl}/api/action-items/${item.id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Actions:', showArchived);
    if (type === 'call') {
      axios.get(`${serverUrl}/api/calls/${id}/action-items?showArchived=${showArchived}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        console.log(res);
        setActionItems(res.data);
      });
    } else if (type === 'email') {
      axios.get(`${serverUrl}/api/emails/${id}/action-items?showArchived=${showArchived}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        console.log(res);
        setActionItems(res.data);
      });
    }
  }
  const handleFeedback = (feedback) => {
    console.log('Feedback:', feedback);
    let data = {
      details: feedback,
    }

    axios.post(`${serverUrl}/api/feedback/${feedbackId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
      setShowFeedbackModal(false);
    });
    toast({
      title: "Feedback Submitted",
      description: `Thank you for your feedback!`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  const handleResync = async () => {
    try {
      console.log('Resyncing client:', stateClient);
      const response = await axios.post(`${serverUrl}/api/clients/${stateClient[0].id}/resync`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        toast({
          title: "Resync Initiated",
          description: "Client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAssign = (actionItemId, user) => {
    setAssignedUsers(prev => ({
      ...prev,
      [actionItemId]: user
    }));
    
    toast({
      title: "Task Assigned",
      description: `Task assigned to ${user.name}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Box mt={2} bg={'#C4D1DD'} borderRadius={'8px'} p={5}>
        <HStack w={'100%'} h={'100%'} alignItems="flex-start">
          <VStack w={'75%'} align={'flex-start'} h={'100%'}>
            {actionItems?.length === 0 && (
              <Text>No action items found</Text>
            )}
            {actionItems?.map((item, index) => (
              <HStack key={index} w={'100%'} justify={'space-between'}>
                <Box>
                  <HStack>
                    {(item.aiAction && !item.archived) && (
                      <>
                        {item.aiAction && item.aiCompletionStatus === 'PENDING' && (
                          <IconButton aria-label="Execute Action Item" icon={<CheckIcon />} bg={'none'} color={'green'} alignSelf={'flex-start'}
                            isDisabled={!assignedUsers[item.id]}
                            onClick={() => {
                              const data = {
                                details: '',
                                sentiment: 'POSITIVE',
                                actionItemId: item.id,
                              };
                              axios.post(`${serverUrl}/api/feedback`, data, {
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                                },
                              })
                              toast({
                                title: "Executing Action Item", 
                                description: `Executing ${item.description}...`,
                                status: "info",
                                duration: 5000,
                                isClosable: true,
                              })
                              handleApprove(item)
                            }}
                          ></IconButton>
                        )}
                        {item.aiAction && item.aiCompletionStatus === 'PENDING' && (
                          <IconButton aria-label="Remove Action Item" icon={<CloseIcon />} bg={'none'} color={'red'} alignSelf={'flex-start'}
                            onClick={() => {
                              const data = {
                                details: '',
                                sentiment: 'NEGATIVE',
                                actionItemId: item.id,
                              };

                              axios.post(`${serverUrl}/api/feedback`, data, {
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                                },
                              }).then(res => {
                                console.log(res);
                                setFeedbackId(res.data.id);
                                toast({
                                  title: "Action Item Closed",
                                  description: (
                                    <>
                                      Give us feedback on why you closed it by clicking here so we can be more helpful for next time!
                                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                                        <Text color={'white'}>Click here to submit feedback</Text>
                                      </Button>
                                    </>
                                  ),
                                  status: "warning",
                                  duration: 5000,
                                  isClosable: true,
                                })
                                // archive and remove from the list
                                handleArchive(item);
                                setActionItems(actionItems.filter(i => i.id !== item.id));
                              }).catch(error => {
                                console.error("Error submitting feedback:", error);
                                toast({
                                  title: "Error",
                                  description: `There was an error submitting your feedback: ${error.message}`,
                                  status: "error",
                                  duration: 5000,
                                  isClosable: true,
                                });
                              });
                            }}
                          ></IconButton>
                        )}
                      </>
                    )}
                    <Box w={'100%'}>
                      <HStack justify="space-between" w="100%">
                        <Text
                          as={item.aiCompletionStatus === 'SUCCESS' ? 's' : 'span'}
                          color={item.aiCompletionStatus === 'SUCCESS' ? 'gray' : 'black'}
                        >
                          {item.description}
                        </Text>
                        {user?.organization?.settings?.manuallyAssignTasks !== false && (
                          <Menu>
                            <Tooltip label={!userCrms[0]?.crmUsers?.length ? "Please sync your CRM to assign tasks" : ""}>
                              <MenuButton 
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                size="sm"
                                variant="ghost"
                                isDisabled={!userCrms[0]?.crmUsers?.length}
                              >
                                {assignedUsers[item.id] ? `Assigned to ${assignedUsers[item.id].name}` : 'Assign To'}
                              </MenuButton>
                            </Tooltip>
                            <MenuList>
                              {userCrms[0]?.crmUsers?.map(user => (
                                <MenuItem 
                                  key={user.id}
                                  onClick={() => {
                                    handleAssign(item.id, user);
                                  }}
                                >
                                  {user.name}
                                </MenuItem>
                              ))}
                              <MenuItem
                                onClick={() => {
                                  handleAssign(item.id, {id: 'datadasher', name: 'DataDasher'});
                                }}
                              >
                                DataDasher
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </HStack>
                    </Box>
                  </HStack>
                </Box>
                <Tooltip label={showArchived ? "Unarchive" : "Archive"} >
                  <Button size="sm" onClick={() => {
                    toast({
                      title: "Archiving Action Item",
                      description: `Archiving ${item.description}...`,
                      status: "info",
                      duration: 1000,
                      isClosable: true,
                    })
                    if (showArchived) {
                      handleUnarchive(item);
                    } else {
                      handleArchive(item);
                    }
                    setActionItems(actionItems.filter(i => i.id !== item.id));
                  }} alignSelf={'flex-start'}>
                    {showArchived ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </Tooltip>
              </HStack>
            ))}
          </VStack>
          <VStack w={'25%'} alignContent={'flex-start'} justify={'flex-start'} h={'100%'}>
            <HStack w={'100%'} justify={'flex-end'} mt={2}>
              <Text>View Archived Actions</Text>
              <Switch
                onChange={(e) => {
                  const showArchived = e.target.checked;
                  handleShowArchived(showArchived);
                  setShowArchived(showArchived);
                  console.log('Show Archived Actions:', showArchived);
                }}
              />
            </HStack>
          </VStack>
        </HStack>
        <HStack mt={4} justify={'space-between'}>
          <HStack gap={2}>
            {actionItems?.some(item => item.aiAction && item.aiCompletionStatus === 'PENDING') && (
              <Button bg="#9DB4CA"
                onClick={() => toast({
                  title: "Approving All Action Items",
                  description: `Now executing all action items for ${client.name}...`,
                  status: "info",
                  duration: 1000,
                  isClosable: true,
                })}
              >
                <CheckCircleIcon mr={2} color={'green'} />
                Approve All
              </Button>
            )}
            {userCrms.map(crm => (
              <Button bg="#9DB4CA" key={crm.name} onClick={() => {/* Add your CRM link logic here */}}>
                <ExternalLinkIcon mr={2} />
                {crm.name}
              </Button>
            ))}
          </HStack>
          <HStack gap={1}>
            <Button
              onClick={async () => {
                let data = {
                  [type === 'call' ? 'callId' : 'emailId']: id,
                  details: "",
                  sentiment: "POSITIVE"
                }
                let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                  headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                  }
                });
                console.log(res);
                setFeedbackId(res.data.id);
                toast({
                  title: "Correspondence Feedback",
                  description: (
                    <>
                      Feedback on the correspondence submitted.{' '}
                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                        <Text color={'white'}>Click here to submit more details</Text>
                      </Button>
                    </>
                  ),
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                })
              }}
            >
              <Icon as={IoMdHappy} color={'green'} />
            </Button>
            <Button
              onClick={async () => {
                let data = {
                  [type === 'call' ? 'callId' : 'emailId']: id,
                  details: "",
                  sentiment: "NEGATIVE"
                }
                let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                  headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                  }
                });
                console.log(res);
                setFeedbackId(res.data.id);
                toast({
                  title: "Correspondence Feedback",
                  description: (
                    <>
                      Feedback on the correspondence submitted.{' '}
                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                        <Text color={'white'}>Click here to submit more details</Text>
                      </Button>
                    </>
                  ),
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                })
              }}
            >
              <Icon as={IoMdSad} color={'red'} />
            </Button>
          </HStack>
        </HStack>
      </Box>
      <FeedbackModal isOpen={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} type={type} id={feedbackId} onSubmit={handleFeedback} />
    </>
  );
};

export default BlueBox;
