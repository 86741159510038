import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Heading, 
  Switch, 
  Text, 
  VStack, 
  HStack, 
  Grid, 
  GridItem,
  Tooltip,
  useToast,
  Input,
  FormLabel,
  FormControl,
  FormHelperText,
  Button
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Cookies from 'js-cookie';

const Settings = () => {
  const [isHighQuality, setIsHighQuality] = useState(false);
  const [useEmailVoice, setUseEmailVoice] = useState(false);
  const [showEmailComparison, setShowEmailComparison] = useState(false);
  const [hasMicrosoftIntegration, setHasMicrosoftIntegration] = useState(false);
  const [useEmailTemplate, setUseEmailTemplate] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [signatureImage, setSignatureImage] = useState(null);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const standardEmail = `Dear [Client],

I hope this email finds you well. I wanted to follow up on our previous discussion regarding your investment portfolio.

Best regards,
[Name]`;

  const voicedEmail = `Hey [Client]!

Just touching base about what we chatted about last time - you know, that investment portfolio stuff we discussed. I think we've got some really exciting options to explore.

Catch you soon,
[Name]`;

  const defaultTemplate = `Dear {{clientName}},

Thank you for meeting with me on {{todaysDate}}. 

{{body}}.

Please let me know if you have any questions or concerns.

Best regards,
{{advisorName}}
{{signatureImage}}`;

  const quillRef = useRef(null);
  const [manuallyAssignTasks, setManuallyAssignTasks] = useState(false);

  useEffect(() => {
    // Fetch user connections to check for Microsoft integration
    const checkMicrosoftIntegration = async () => {
      try {
        let token = Cookies.get('jwtToken');
        const response = await axios.get(`${serverUrl}/api/user-connections`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        console.log('Connections:', response.data);
        setHasMicrosoftIntegration(response.data.some(conn => conn.name === 'Microsoft'));
        // Also fetch current settings
        const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        const settings = settingsResponse.data;
        console.log('settings', settings);
        setUseEmailVoice(settings?.settings?.useEmailVoice || false);
        setUseEmailTemplate(settings?.settings?.useEmailTemplate || false);
        setEmailTemplate(settings?.settings?.emailTemplate || defaultTemplate);
        setSignatureImage(settings?.settings?.signatureImage || null);
      } catch (error) {
        console.error('Error fetching user connections:', error);
      }
    };

    checkMicrosoftIntegration();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = Cookies.get('jwtToken');
        const response = await axios.get(`${serverUrl}/api/organization-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        
        setManuallyAssignTasks(response.data.settings?.manuallyAssignTasks || false);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    
    fetchSettings();
  }, []);

  const handleToggle = () => {
    setIsHighQuality(!isHighQuality);
    console.log('High Quality Transcription:', !isHighQuality);
  };

  const handleEmailVoiceToggle = async () => {
    if (!hasMicrosoftIntegration) return;

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            useEmailVoice: !useEmailVoice
          }
        }
      });

      if (response.status !== 200) throw new Error('Failed to update settings');

      setUseEmailVoice(!useEmailVoice);
      setShowEmailComparison(!useEmailVoice);

      toast({
        title: 'Settings updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating settings',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEmailTemplateToggle = async () => {
    if (!hasMicrosoftIntegration) return;

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            useEmailTemplate: !useEmailTemplate,
            emailTemplate: emailTemplate || defaultTemplate
          }
        }
      });

      if (response.status !== 200) throw new Error('Failed to update settings');

      setUseEmailTemplate(!useEmailTemplate);
      if (!emailTemplate) setEmailTemplate(defaultTemplate);

      toast({
        title: 'Settings updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating settings',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTemplateChange = async (content) => {
    setEmailTemplate(content);
    try {
      await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            emailTemplate: content
          }
        }
      });
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      if (img.width < 2550) {
        toast({
          title: 'Image too small',
          description: 'Signature image must be at least 2550 pixels wide',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append('signature', file);
        const token = Cookies.get('jwtToken');
        const response = await axios.post(`${serverUrl}/api/upload-signature`, formData, {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });

        setSignatureImage(response.data.url);
        toast({
          title: 'Signature uploaded successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error uploading signature',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  };

  const handleManualTaskAssignmentChange = async (event) => {
    try {
      await axios.post(`${serverUrl}/api/organization-settings`, {
        settings: {
          manuallyAssignTasks: !manuallyAssignTasks
        }
      });
      
      setManuallyAssignTasks(!manuallyAssignTasks);
      toast({
        title: "Settings updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating settings:', error);
      toast({
        title: "Error updating settings",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="white" p={8} overflowY="auto" h="95vh">
      <Heading color={'#00417D'} pb={4}>Settings</Heading>
      <VStack align="start" spacing={4} mt={5}>
        {/* User Settings Section */}
        <Box w="100%">
          <Heading color={'#00417D'} fontSize={'24px'} mb={4}>User Settings</Heading>
          <VStack align="start" spacing={4}>
            <HStack justify="space-between" w="40%">
              <Text>Enable Beta Features</Text>
              <Switch isChecked={isHighQuality} onChange={handleToggle} />
            </HStack>
            
            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Use Email Template for Post-Meeting Follow-ups</Text>
                <Tooltip 
                  label={hasMicrosoftIntegration 
                    ? "Enable custom email template for meeting follow-ups" 
                    : "Microsoft integration required for this feature"}
                >
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch 
                isChecked={useEmailTemplate} 
                onChange={handleEmailTemplateToggle}
                isDisabled={!hasMicrosoftIntegration}
              />
            </HStack>

            {useEmailTemplate && (
              <VStack spacing={4} w="100%" mt={4} align="start">
                <Text fontWeight="bold">Email Template</Text>
                <Text fontSize="sm" color="gray.600">
                  Available variables: clientName, todaysDate, body, signatureImage, advisorName
                </Text>
                <Box w="100%" h="400px" className="preserve-whitespace">
                  <ReactQuill 
                    ref={quillRef}
                    value={emailTemplate} 
                    onChange={handleTemplateChange}
                    style={{ height: '350px' }}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['link', 'image'],
                        ['clean']
                      ],
                      clipboard: {
                        matchVisual: false
                      },
                      keyboard: {
                        bindings: {
                          tab: false,
                          'indent-0': false
                        }
                      }
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet',
                      'link', 'image'
                    ]}
                  />
                </Box>
                
                <FormControl>
                  <FormLabel>Signature Image</FormLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleSignatureUpload}
                    display="none"
                  />
                  <Button onClick={() => fileInputRef.current.click()}>
                    Upload Signature
                  </Button>
                  <FormHelperText>
                    Image must be at least 2550 pixels wide
                  </FormHelperText>
                </FormControl>
                
                {signatureImage && (
                  <Box mt={2}>
                    <img src={signatureImage} alt="Signature" style={{ maxWidth: '300px' }} />
                  </Box>
                )}
              </VStack>
            )}

            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Write Emails in your Voice</Text>
                <Tooltip 
                  label={hasMicrosoftIntegration 
                    ? "Enable AI to write emails matching your writing style" 
                    : "Microsoft integration required for this feature"}
                >
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch 
                isChecked={useEmailVoice} 
                onChange={handleEmailVoiceToggle}
                isDisabled={!hasMicrosoftIntegration}
              />
            </HStack>

            {(showEmailComparison || useEmailVoice) && (
              <Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%" mt={4}>
                <GridItem>
                  <Box p={4} borderWidth="1px" borderRadius="md">
                    <Text fontWeight="bold" mb={2}>Standard Email</Text>
                    <Text whiteSpace="pre-wrap">{standardEmail}</Text>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box p={4} borderWidth="1px" borderRadius="md">
                    <Text fontWeight="bold" mb={2}>Your Voice</Text>
                    <Text whiteSpace="pre-wrap">{voicedEmail}</Text>
                  </Box>
                </GridItem>
              </Grid>
            )}
          </VStack>
        </Box>

        {/* Organization Settings Section */}
        <Box w="100%" mt={8}>
          <Heading color={'#00417D'} fontSize={'24px'} mb={4}>Organization Settings</Heading>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="manual-task-assignment" mb="0">
              Manually Assign Tasks For CRM Field Updates
            </FormLabel>
            <Switch
              id="manual-task-assignment"
              isChecked={manuallyAssignTasks}
              onChange={handleManualTaskAssignmentChange}
            />
          </FormControl>
        </Box>
      </VStack>
    </Box>
  );
};

if (typeof window !== 'undefined') {
  const Quill = ReactQuill.Quill;
  const LineBreak = Quill.import('blots/break');
  const Embed = Quill.import('blots/embed');
  
  class CustomLineBreak extends LineBreak {
    length() {
      return 1;
    }
    value() {
      return '\n';
    }
  }
  
  CustomLineBreak.blotName = 'linebreak';
  CustomLineBreak.tagName = 'BR';
  
  Quill.register(CustomLineBreak, true);
}

export default Settings;
