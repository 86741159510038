import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, useToast, Text, Tooltip, Checkbox } from '@chakra-ui/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const RedtailConnect = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isConsented, setIsConsented] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const redtailDisclaimer = `
  By connecting to Redtail, you consent to the secure transfer of information via Redtail's API.
  The integration will allow DataDasher to push and pull specific data fields such as client names,
  addresses, and relevant financial information. This ensures that your CRM is up-to-date with the latest
  information from your communications. Please ensure that you have the necessary permissions to
  share this data with Redtail. Data privacy and security are a top priority for DataDasher throughout this process.
  `; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const token = Cookies.get('jwtToken');
      const response = await axios.post(`${serverUrl}/api/redtail/authenticate`, { username, password }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast({
        title: "Success",
        description: "RedTail connected successfully. Fetching contacts...",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message || "An error occurred during authentication",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="white" p={8}>
      <Box mt={6} mb={6}>
        <Tooltip label={redtailDisclaimer} aria-label="Redtail Data Disclaimer" maxWidth="500px">
          <Text color="gray.600" fontSize="sm" cursor="pointer">
            View Data Disclaimer
          </Text>
        </Tooltip>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel>Username</FormLabel>
          <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel>Password</FormLabel>
          <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </FormControl>
        <FormControl display="flex" alignItems="center" mt={4}>
          <Checkbox
            isChecked={isConsented}
            onChange={(e) => setIsConsented(e.target.checked)}
          >
            I have read and consent to the data disclaimer.
          </Checkbox>
        </FormControl>
        <Button
          mt={4}
          type="submit"
          bg="#00417D"
          color="white"
          isLoading={isLoading}
          loadingText="Connecting..."
          isDisabled={!isConsented}
        >
          Connect
        </Button>
      </form>
    </Box>
  );
};

export default RedtailConnect;
